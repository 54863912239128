import React, { ReactElement, FC } from "react"
import { Row, Col, Typography } from "antd"
import ReactMarkdown from "react-markdown"
import { StaticImage } from "gatsby-plugin-image"

import SocialNetworkLink from "../social-network-link/social-network-link"

import "./hero-contact.less"
import { I_SocialNetwork, I_SocialNetworks } from "../../global/interfaces"

const { Title } = Typography

interface I_Props {
  title: string
  subtitle: string
  text: string
  socialNetwork: I_SocialNetworks
}

const HeroContactComponent: FC<I_Props> = ({
  title,
  subtitle,
  text,
  socialNetwork,
}: I_Props): ReactElement => {
  return (
    <section className="hero-contact">
      <Row className="hero-contact__wrapper">
        <Col xs={24} lg={16} className="hero-contact__col-left">
          <Title className="hero-contact__title">{title}</Title>
          <Title level={2} className="hero-contact__subtitle">
            {subtitle}
          </Title>
          <ReactMarkdown children={text} />
          <div className="hero-contact__icons">
            {socialNetwork.socialNetworks.map(
              ({ id, type, link }: I_SocialNetwork) => (
                <SocialNetworkLink key={id} id={id} type={type} link={link} />
              )
            )}
          </div>
        </Col>
        <Col xs={0} lg={8}>
          <StaticImage src="../../assets/contact.png" alt={title} />
        </Col>
      </Row>
      <StaticImage src="../../assets/curves_01.png" alt="" />
    </section>
  )
}

export default HeroContactComponent
