import React, { ReactElement, FC } from "react"
import { graphql } from "gatsby"
import { Row, Col } from "antd"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import CookiesBanner from "../components/cookies-banner/cookies-banner"
import CountryLanguageBanner from "../components/country-language-banner/country-language-banner"
import HeroContact from "../components/hero-contact/hero-contact"
import Container from "../components/container/container"
import Questions from "../components/questions/questions"
import ContactForm from "../components/contact-form/contact-form"
import {
  I_CountryLanguage,
  I_Navigation,
  I_ContactForm,
  I_Tab,
  I_Footer,
  I_SocialNetworks,
  I_CookiesBanner,
  I_SEO,
  I_Location,
} from "../global/interfaces"

interface I_Strapi {
  countryLanguage: I_CountryLanguage
  navigation: I_Navigation
  contactForm: I_ContactForm
  socialNetwork: I_SocialNetworks
  footer: I_Footer
  cookiesBanner: I_CookiesBanner
}

interface I_PageContext {
  title: string
  subtitle: string
  text: string
  questionsTitle: string
  questionsText: string
  questions: I_Tab[]
  seo: I_SEO
  baseUrl: string
}

interface I_Props {
  pageContext: I_PageContext
  data: {
    strapi: I_Strapi
  }
  location: I_Location
}

const Contact: FC<I_Props> = ({
  pageContext,
  data,
  location,
}: I_Props): ReactElement => {
  const {
    countryLanguage,
    navigation,
    contactForm,
    socialNetwork,
    footer,
    cookiesBanner,
  } = data.strapi
  const {
    title,
    subtitle,
    text,
    questionsTitle,
    questionsText,
    questions,
    baseUrl,
  } = pageContext

  return (
    <>
      <SEO {...pageContext.seo} baseUrl={baseUrl} />
      <CookiesBanner {...cookiesBanner} />
      <CountryLanguageBanner {...countryLanguage} baseUrl={baseUrl} />
      <Layout
        navigation={navigation}
        footer={footer}
        location={location}
        baseUrl={baseUrl}
      >
        <HeroContact
          title={title}
          subtitle={subtitle}
          text={text}
          socialNetwork={socialNetwork}
        />
        <Container>
          <Row gutter={60}>
            <Col xs={24} lg={12}>
              <Questions
                title={questionsTitle}
                text={questionsText}
                questions={questions}
              />
            </Col>
            <Col xs={24} lg={12}>
              <ContactForm {...contactForm} />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactPageQuery($locale: String) {
    strapi {
      countryLanguage(locale: $locale) {
        text
        countryOptions {
          label
          options {
            id
            text
            value
          }
        }
        languageOptions {
          label
          options {
            id
            text
            value
          }
        }
        button {
          text
        }
      }
      navigation(locale: $locale) {
        links {
          id
          link
          target
          text
        }
      }
      contactForm(locale: $locale) {
        nameInput {
          label
          placeholder
          error {
            id
            type
            message
          }
          type
          name
        }
        emailInput {
          label
          placeholder
          error {
            id
            type
            message
          }
          type
          name
        }
        messageInput {
          label
          placeholder
          error {
            id
            type
            message
          }
          type
          name
        }
        countryInput {
          label
          options {
            id
            text
            value
          }
        }
        uploadInput {
          text
          name
        }
        submitButton {
          text
          name
        }
        companyEmail
        successNotification {
          message
          description
        }
        errorNotification {
          message
          description
        }
      }
      socialNetwork(locale: $locale) {
        title
        socialNetworks {
          id
          type
          link
        }
      }
      footer(locale: $locale) {
        copyright
        linkList {
          id
          title
          links {
            id
            text
            link
            target
          }
        }
      }
      cookiesBanner(locale: $locale) {
        title
        text
        acceptButton {
          text
        }
      }
    }
  }
`
