import React, { ReactElement, FC } from "react"
import { Steps, Typography } from "antd"
import ReactMarkdown from "react-markdown"

import { I_Tab } from "../../global/interfaces"

import "./questions.less"

const { Title } = Typography
const { Step } = Steps

interface I_Props {
  title: string
  text: string
  questions: I_Tab[]
}

const QuestionsComponent: FC<I_Props> = ({
  title,
  text,
  questions,
}: I_Props): ReactElement => {
  return (
    <section className="questions">
      <Title level={2} className="questions__title">
        {title}
      </Title>
      <ReactMarkdown children={text} className="questions__text" />
      <Steps direction="vertical" size="small" current={0} status="wait">
        {questions.map(({ id, title, text }: I_Tab) => (
          <Step key={id} title={title} description={text} />
        ))}
      </Steps>
    </section>
  )
}

export default QuestionsComponent
