import React, { ReactElement, FC, useState, useEffect } from "react"
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Upload,
  notification,
  Row,
  Col,
} from "antd"
import { UploadOutlined, MailOutlined } from "@ant-design/icons"
import useSWR from "swr"

import { I_ContactForm, I_Option } from "../../global/interfaces"
import { requiredRule, nameValidator, typeRule } from "../../global/utils/form"
import { TypeRule, NotificationPlacement } from "../../global/enums"
import { postFormData } from "../../services/api"

import "./contact-form.less"

const { Option } = Select
const { useForm, Item } = Form
const { TextArea } = Input

const ContactFormComponent: FC<I_ContactForm> = ({
  nameInput,
  emailInput,
  messageInput,
  countryInput,
  uploadInput,
  submitButton,
  companyEmail,
  successNotification,
  errorNotification,
}: I_ContactForm): ReactElement => {
  const [file, setFile] = useState<any>(null)
  const [postFormDataParams, setPostFormDataParams] = useState<any>(null)
  const [processingSubscription, setProcessingSubscription] = useState(false)
  const { data, error } = useSWR(postFormDataParams, postFormData)
  const [form] = useForm()
  const initialValues = {
    name: "",
    email: "",
    message: "",
    country: countryInput.options[0].value,
  }

  useEffect(() => {
    if (data || error) {
      setProcessingSubscription(false)
      setPostFormDataParams(null)
      form.resetFields()
      if (data) {
        if (data.error) {
          notification.error({
            ...errorNotification,
            placement: NotificationPlacement.BottomRight,
          })
        } else {
          notification.success({
            ...successNotification,
            placement: NotificationPlacement.BottomRight,
          })
        }
      } else {
        notification.error({
          ...errorNotification,
          placement: NotificationPlacement.BottomRight,
        })
      }
    }
  }, [data, error])

  const handleOnFinish = (formData: any) => {
    delete formData.file
    const url = `${process.env.GATSBY_STRAPI_API_URL}/contact-messages`
    setProcessingSubscription(true)
    setPostFormDataParams([url, formData, file])
  }

  const handleBeforeUpload = (file: any) => {
    setFile(file)
    return false
  }

  return (
    <Card
      className="contact-form"
      title={
        <>
          <MailOutlined />
          <a className="contact-form__email" href={`mailto:${companyEmail}`}>
            {companyEmail}
          </a>
        </>
      }
    >
      <Form form={form} initialValues={initialValues} onFinish={handleOnFinish}>
        <Item
          name="name"
          rules={[
            requiredRule(nameInput.error),
            { validator: (_, value) => nameValidator(value, nameInput.error) },
          ]}
        >
          <Input placeholder={nameInput.placeholder} />
        </Item>
        <Item
          name="email"
          rules={[
            requiredRule(emailInput.error),
            typeRule(emailInput.error, TypeRule.Email),
          ]}
        >
          <Input placeholder={emailInput.placeholder} />
        </Item>
        <Item name="message">
          <TextArea
            placeholder={messageInput.placeholder}
            autoSize={{ minRows: 4 }}
          />
        </Item>
        <Item name="country">
          <Select placeholder={countryInput.label}>
            {countryInput.options.map(({ id, text, value }: I_Option) => (
              <Option key={id} value={value}>
                {text}
              </Option>
            ))}
          </Select>
        </Item>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Form.Item
              className="contact-form__upload"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={(e: any) => {
                if (Array.isArray(e)) {
                  return e
                }
                return e && e.fileList
              }}
            >
              <Upload
                maxCount={1}
                beforeUpload={handleBeforeUpload}
                accept="image/*,.pdf"
              >
                <Button
                  icon={<UploadOutlined />}
                  className="contact-form__upload-button"
                >
                  {uploadInput.text}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Button
              type="primary"
              htmlType="submit"
              className="contact-form__submit"
              loading={processingSubscription}
            >
              {submitButton.text}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default ContactFormComponent
